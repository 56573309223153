import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import { Button, Chip, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { VerifiedUser } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'

import Copyright from '~/components/copyright'
import CreateUser from '~/components/create-user'
import EditUser from '~/components/edit-user'
import { AppRoutePath } from '~/constants/paths'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { PerfilAdmin } from '~/enums/enum'
import { useUsersQuery } from '~/store/user/user-service'
import { useUserStore } from '~/store/user/user-store'
import { FormsHandleCreate, FormsHandleEdit } from '~/types/user-types/user-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import UserCardFilter from './components/user-card-filter'
import strings from './strings'
import { useStyles } from './style'

const listPageSize = 100

const Users: React.FC = () => {
  const classes = useStyles()
  const [page, setPage] = React.useState(1)
  const { filters, setFilters } = useUserStore()

  const { data, isFetching } = useUsersQuery({
    size: listPageSize,
    page,
    filters,
  })

  const OpenModalCreateUser = () => {
    UserCreateRef.current?.openModalCreateForms()
  }

  const editUserRef = useRef<FormsHandleEdit>(null)
  const UserCreateRef = useRef<FormsHandleCreate>(null)

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      flex: 0.3,
      filterable: false,
    },
    {
      field: 'perfil_admin',
      headerName: 'Perfil Admin',
      width: 160,
      filterable: false,
      renderCell: (params) => {
        return PerfilAdmin[params.row?.perfil_admin] ? PerfilAdmin[params.row?.perfil_admin] : ''
      },
      valueGetter: (params) => PerfilAdmin[params.row?.perfil_admin] ?? '',
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: 120,
      flex: 0.2,
      filterable: false,
      renderCell: (params) => maskInterceptor('cpf')(params.row?.cpf),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 140,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      filterable: false,
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 120,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params) => maskInterceptor('phone')(params.row?.celular),
    },
    {
      field: 'role',
      headerName: 'Cargo',
      width: 130,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      filterable: false,
      valueGetter: (params) => params.row?.role?.nome,
      renderCell: (params) => params.row?.role?.nome,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      valueGetter: (params) => (params.row?.status === '1' ? 'Ativo' : 'Inativo'),
      renderCell: (params) => {
        return (
          <Chip
            id="chips-status"
            label={params.row?.status === '1' ? 'Ativo' : 'Inativo'}
            style={
              params.row?.status === '1'
                ? { backgroundColor: '#24d2b5', color: '#fff' }
                : { backgroundColor: '#ff5c6c', color: '#fff' }
            }
          />
        )
      },
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params) => {
        return (
          <Link to={`${AppRoutePath.USERSDETAIL.replace(':userId', params?.row?.id)}`}>
            <Button variant="contained" color="primary" size="small" endIcon={<VerifiedUser />}>
              {strings.buttonUserTitle}
            </Button>
          </Link>
        )
      },
    },
  ]
  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          item
          xs={12}
          style={{ marginBottom: '1.3rem', justifyContent: 'space-between' }}
        >
          <Typography variant="h4" color="primary">
            {strings.usersPage.title}
          </Typography>
          <Button
            id="button-adicionar-usuario"
            variant="contained"
            endIcon={<AddIcon />}
            style={{
              backgroundColor: '#48bbe6',
              color: '#fff',
              fontSize: '14px',
            }}
            onClick={() => {
              OpenModalCreateUser()
            }}
          >
            {strings.buttonTitle.title}
          </Button>
        </Grid>
        <Grid container item sm={12} xs={12}>
          <UserCardFilter onChangeFilter={setFilters} />
        </Grid>
        {isFetching ? (
          <Grid container item sm={12} xs={12} className={classes.positionCircularProgress}>
            <CircularProgress variant="indeterminate" size="3rem" />
          </Grid>
        ) : (
          <Grid container item sm={12} xs={12} className={classes.resumeAuditTable}>
            <DataGrid
              page={page - 1}
              rows={data?.data || []}
              rowCount={data?.totalRowCount || 0}
              columns={columns}
              disableSelectionOnClick
              loading={isFetching}
              localeText={GRID_DEFAULT_LOCALE_TEXT}
              onPageChange={(newPage) => setPage(newPage + 1)}
              pageSize={listPageSize}
              rowsPerPageOptions={[listPageSize]}
              paginationMode="server"
            />
          </Grid>
        )}
      </Paper>
      <Copyright />
      <EditUser ref={editUserRef} />
      <CreateUser ref={UserCreateRef} />
    </Grid>
  )
}

export default Users
