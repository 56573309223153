const strings = {
  document: 'CPF',
  email: 'E-mail',
  phone: 'Celular',
  situation: 'Situação',
  cargo: 'Cargo',
  adminInfo: 'Perfil Administrativo',
  agpro: 'agPRO',
  userInfoTitle: 'Dados do usuário',
}

export default strings
