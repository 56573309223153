import { useEffect, useState } from 'react'

import { Button, ButtonGroup, Chip, Grid, Paper, Typography } from '@material-ui/core'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { Copyright, Delete } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import Swal from 'sweetalert2'

import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useFilterAuditState } from '~/store/audit/filter-audit'
import { useHarvestStore } from '~/store/harvest-registration/harvest-store'

import { HarvestModal } from './components/harvest-registration-modal'
import strings from './strings'
import { useStyles } from './styles'

export const HarvestRegistration: React.FC = () => {
  const classes = useStyles()
  const { items } = useFilterAuditState()
  const setFilter = useFilterAuditState((state) => state.setFilter)
  const [harvestRegistrationId, setHarvestRegistrationId] = useState<any>(undefined)
  const {
    harvest,
    modalController,
    getAllHarvestRegistration,
    getHarvestRegistrationById,
    deleteHarvestRegistration,
  } = useHarvestStore()

  useEffect(() => {
    getAllHarvestRegistration()
  }, [getAllHarvestRegistration])

  const dataList = harvest

  const openModal = (data: any) => {
    modalController(true)
    if (!data) {
      setHarvestRegistrationId(undefined)
    } else {
      setHarvestRegistrationId(data)
    }
  }

  const deleteRelacionamento = async (harvestId: number) => {
    const alertResult = await Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja remover a Safra?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    })

    if (alertResult.isConfirmed) {
      await deleteHarvestRegistration(harvestId)
      await getAllHarvestRegistration()
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      renderCell: (params) => params.row?.id,
      type: 'string',
    },
    {
      field: 'nome',
      headerName: 'Safra',
      width: 280,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      renderCell: (params) => params.row?.description,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Chip
            label={params.row?.status === '1' ? 'Ativo' : 'Inativo'}
            style={
              params.row?.status === '1'
                ? { backgroundColor: '#24d2b5', color: '#fff' }
                : { backgroundColor: '#ff5c6c', color: '#fff' }
            }
          />
        )
      },
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <ButtonGroup disableElevation variant="contained">
            <Button
              variant="contained"
              style={{
                backgroundColor: '#48bbe6',
                color: '#fff',
                fontSize: '10px',
              }}
              onClick={async () => {
                openModal(params?.row?.id)
                await getHarvestRegistrationById(Number(params?.row?.id))
              }}
            >
              <EditIcon />
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#ff5c6c',
                color: '#fff',
                fontSize: '10px',
              }}
              onClick={() => deleteRelacionamento(Number(params?.row?.id))}
            >
              <Delete />
            </Button>
          </ButtonGroup>
        )
      },
    },
  ]
  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12} className={classes.headerContainer}>
          <Typography variant="h4" color="primary">
            {strings.harvestRegistrationPage.title}
          </Typography>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            style={{
              backgroundColor: '#48bbe6',
              color: '#fff',
              fontSize: '14px',
            }}
            onClick={() => {
              openModal(null)
            }}
          >
            {strings.buttonTitle.title}
          </Button>
        </Grid>
        <Grid container item xs={12} className={classes.resumeAuditTable}>
          <DataGrid
            rows={dataList}
            columns={columns}
            disableSelectionOnClick
            filterModel={{ items }}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </Grid>
      </Paper>
      <Copyright />
      <HarvestModal harvestId={harvestRegistrationId} />
    </Grid>
  )
}
