const strings = {
  title: 'Dados da consultoria',
  titleEndereco: 'Endereço',
  document: 'CPF/CNPJ',
  email: 'Email',
  celular: 'Celular',
  situacao: 'Situacao',
  cadastro: 'Cadastrado em: ',
  nomeFantasia: 'Nome Fantasia',
}

export default strings
