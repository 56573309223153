import React, { useRef } from 'react'

import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  IconButton,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'

import ConsultancyEdit from '~/pages/consultancy/consultancy-details/components/Modals/Edit'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'
import { FormsHandleEdit } from '~/types/consultancy-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './styles'

const ConsultancyData: React.FC = () => {
  const classes = useStyles()
  const { Consultacy, consultancyById } = useConsultacyStore()
  const date = new Date(Consultacy?.created_at)
  const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
  const editConsultancyRef = useRef<FormsHandleEdit>(null)

  const openModalConsultancy = (consultacy: any) => {
    editConsultancyRef.current?.openModalEditForms(consultacy)
  }

  const getConsultancyById = async (consultancyId: number) => {
    const data = consultancyById(Number(consultancyId))
    openModalConsultancy(data)
  }

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid item xs={12} sm={12} container spacing={2}>
        <Grid item xs={12} container justifyContent="space-between" alignContent="center">
          <Typography variant="h4">{strings.title}</Typography>
          <IconButton
            component="span"
            color="primary"
            onClick={async () => getConsultancyById(Number(Consultacy?.id))}
            id="button-editar-consultancy"
          >
            <Edit />
          </IconButton>
        </Grid>

        <Grid item xs={12} sm={12} container justifyContent="space-between" alignItems="center">
          <Grid item sm={4}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.nomeFantasia}
                  secondary={Consultacy?.fantasia}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={3}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.document}
                  secondary={
                    Consultacy?.documento.length === 11
                      ? maskInterceptor('cpf')(Consultacy?.documento)
                      : maskInterceptor('cnpj')(Consultacy?.documento)
                  }
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={5}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.email}
                  secondary={Consultacy?.email}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item sm={4}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.celular}
                  secondary={maskInterceptor('phone')(Consultacy?.celular)}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={8}>
            <List>
              <ListItem>
                <Grid container direction="column" style={{ maxWidth: '120px' }}>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={strings.situacao}
                  />
                  <Chip
                    label={Consultacy?.situacao === '1' ? 'Ativo' : 'Inativo'}
                    style={
                      Consultacy?.situacao === '1'
                        ? { backgroundColor: '#24d2b5', color: '#fff' }
                        : { backgroundColor: '#ff5c6c', color: '#fff' }
                    }
                  />
                </Grid>
              </ListItem>
            </List>
          </Grid>
          <Divider variant="fullWidth" component="article" style={{ width: '100%' }} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          container
          justifyContent="space-between"
          alignItems="flex-start"
          style={{
            flexDirection: 'column',
          }}
        >
          <Grid item container alignItems="center">
            <Typography variant="h5" style={{ marginLeft: '10px' }}>
              {strings.titleEndereco}
            </Typography>
          </Grid>

          <Grid item sm={8}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={`${Consultacy?.city?.descricao} - ${Consultacy?.city?.state?.sigla} / Brasil`}
                  secondary={`${Consultacy?.rua} Nº ${Consultacy?.numero}, Bairro ${
                    Consultacy?.bairro
                  }, CEP: ${maskInterceptor('zipCode')(Consultacy?.cep)}`}
                />
              </ListItem>
            </List>
          </Grid>

          <Divider variant="fullWidth" component="article" style={{ width: '100%' }} />
        </Grid>

        <Grid item xs={12} sm={12} container justifyContent="space-between" alignItems="center">
          <Grid item sm={8}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.createAt,
                  }}
                  primary={`${strings.cadastro}${parseDate}`}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
      <ConsultancyEdit ref={editConsultancyRef} />
    </Paper>
  )
}

export default ConsultancyData
