import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Grid, Paper } from '@material-ui/core'

import HeadersDetails from '~/shared/headers-details/headers-details'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'

import ConsultancyClient from './components/consultancy-client'
import ConsultancyData from './components/consultancy-data'
import UserConsultancy from './components/user-consultancy'
import { useStyles } from './style'

type Params = {
  consultancyId: string
}

const ConsultancyDetails: React.FC = () => {
  const classes = useStyles()
  const { consultancyId } = useParams<Params>()
  const getConsultacyById = useConsultacyStore((state) => state.consultancyById)
  const getConsultacyWithClient = useConsultacyStore((state) => state.clientConsultancy)
  const getConsultacyWithUser = useConsultacyStore((state) => state.userConsultancy)

  const { Consultacy } = useConsultacyStore()
  const history = useHistory()

  const onBack = () => {
    history.goBack()
  }

  useEffect(() => {
    getConsultacyById(consultancyId)
    getConsultacyWithClient(consultancyId)
    getConsultacyWithUser(consultancyId)
  }, [])

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <HeadersDetails nameTitle={Consultacy?.nome} onClickBack={onBack} />

        <Grid container item xs={12} spacing={0} className={classes.bodyInformation}>
          <ConsultancyData />
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <UserConsultancy />
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <ConsultancyClient />
        </Grid>
      </Paper>
    </Grid>
  )
}

export default ConsultancyDetails
