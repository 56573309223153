import React, { useState, useEffect, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  Paper,
  Typography,
  Chip,
  Button,
  TextField,
  ListItem,
  ListItemText,
  ButtonGroup,
} from '@material-ui/core'
import { GridColDef, DataGrid, GridColumnHeaderParams } from '@material-ui/data-grid'
import { Delete, Edit as EditIcon } from '@material-ui/icons'
import { createFilterOptions } from '@material-ui/lab'
import Autocomplete from '@mui/material/Autocomplete'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import SearchDataGrid from '~/components/search-data-grid'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { ProfileCodes, TypeCargos } from '~/enums/enum'
import EditUser from '~/pages/consultancy/consultancy-details/components/Modals/Edit/edit-user'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'
import { useFilterConsultancyUserState } from '~/store/consultancy/filter-user-consultancy'
import { FormsHandleEdit } from '~/types/user-types/user-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './styles'

const schema = yup.object().shape({
  usuario_id: yup.string().required('Campo obrigatório'),
})

interface UserListI {
  nome: string
  email: string
  id: number
}

type Params = {
  consultancyId: string
}

const UserConsultancy: React.FC = () => {
  const classes = useStyles()
  const { User, usersAvailable: usersFromStore } = useConsultacyStore()
  const { items } = useFilterConsultancyUserState()
  const setFilter = useFilterConsultancyUserState((state) => state.setFilter)
  const OPTIONS_LIMIT = 80
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    context: '',
  })
  const { consultancyId } = useParams<Params>()
  const defaultFilterOptions = createFilterOptions<UserListI>()
  const filterOptions = (options: UserListI[], state: any): UserListI[] =>
    defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT)

  const [users, setUsers] = useState<UserListI[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [usuario, setUsuario] = useState<UserListI | null>(null)
  const [selectedOption, setSelectedOption] = useState<UserListI | null>(() => {
    const initialUserId = getValues('usuario_id')
    if (initialUserId) {
      return users.find((user) => user.id === initialUserId) || null
    }
    return null
  })

  const {
    messageError,
    requestGetConsultantConsultancy,
    requestCreateConsultantConsultancy,
    userConsultancyById,
  } = useConsultacyStore()

  const requestDeleteConsultantConsultancy = useConsultacyStore(
    (state) => state.requestDeleteConsultantConsultancy,
  )

  useEffect(() => {
    async function loadData() {
      await requestGetConsultantConsultancy()
    }
    loadData()
  }, [])

  const onSubmit: SubmitHandler<any> = async () => {
    await requestCreateConsultantConsultancy(getValues('usuario_id'), consultancyId)
    await requestGetConsultantConsultancy()
    setUsuario(null)
    setValue('usuario_id', '')
  }

  const editUserRef = useRef<FormsHandleEdit>(null)

  const OpenModalEditUser = (user: any) => {
    editUserRef.current?.openModalEditForms(user)
  }

  const getUserConsultancyById = async (userId: string) => {
    const data = userConsultancyById(userId)
    OpenModalEditUser(data)
  }

  const deleteRelacionamento = async (userId: string) => {
    const alertResult = await Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja remover o vínculo do Consultor com a Consultoria?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    })

    if (alertResult.isConfirmed) {
      await requestDeleteConsultantConsultancy(userId, consultancyId)
      await requestGetConsultantConsultancy()
    }
  }

  useEffect(() => {
    setUsers(usersFromStore)
    if (selectedOption) {
      const updatedSelectedOption = usersFromStore.find(
        (user: { id: number }) => user.id === selectedOption.id,
      )
      setSelectedOption(updatedSelectedOption || null)
    }
  }, [usersFromStore, selectedOption])

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      flex: 0.3,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params?.row?.nome,
    },
    {
      field: 'cargo_id',
      headerName: 'Cargo',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => TypeCargos[params?.row?.cargo_id],
      renderCell: (params) => TypeCargos[params?.row?.cargo_id],
    },
    {
      field: 'perfil_id',
      headerName: 'Perfil',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => ProfileCodes[params?.row?.perfil_id],
      renderCell: (params) => ProfileCodes[params?.row?.perfil_id],
    },
    {
      field: 'email',
      headerName: 'email',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params?.row?.email,
    },
    {
      field: 'celular',
      headerName: 'celular',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params?.row?.celular,
      renderCell: (params) => maskInterceptor('phone')(params?.row?.celular),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      minWidth: 110,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => (params.row?.status === '1' ? 'Ativo' : 'Inativo'),
      renderCell: (params) => {
        return (
          <Chip
            label={params.row?.status === '1' ? 'Ativo' : 'Inativo'}
            style={
              params.row?.status === '1'
                ? { backgroundColor: '#24d2b5', color: '#fff' }
                : { backgroundColor: '#ff5c6c', color: '#fff' }
            }
          />
        )
      },
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              id="button-edit-consultor"
              variant="contained"
              style={{
                backgroundColor: '#48bbe6',
                color: '#fff',
                fontSize: '10px',
              }}
              onClick={async () => getUserConsultancyById(params.row.id)}
            >
              <EditIcon />
            </Button>
            <Button
              id="button-delete-equipe"
              variant="contained"
              color="secondary"
              className={classes.buttonDelete}
              onClick={() => deleteRelacionamento(params.row.id)}
            >
              <Delete />
            </Button>
          </ButtonGroup>
        )
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
  ]

  return (
    <Grid item xs className={classes.gridMain}>
      <Paper variant="outlined" className={classes.paper}>
        <Grid item xs={12} sm={12} container spacing={2}>
          <Grid item xs={12} sm={12} container>
            <Grid item xs={12} sm={12} container justifyContent="space-between" alignItems="center">
              <Typography variant="h4" align="left">
                {strings.title}
              </Typography>
              <Grid item>
                <Grid container item>
                  <Controller
                    name="usuario_id"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <Autocomplete<UserListI>
                        {...field}
                        id={(getValues('usuario_id') || '').toString()}
                        options={users}
                        value={selectedOption}
                        fullWidth
                        filterOptions={filterOptions}
                        sx={{ width: 425 }}
                        getOptionLabel={(option: UserListI) => (option.nome ? option.nome : '')}
                        onChange={(_event, newValue) => {
                          if (newValue) {
                            setSelectedOption(newValue)
                            setValue('usuario_id', newValue.id.toString())
                          } else {
                            setSelectedOption(null)
                            setValue('usuario_id', '')
                          }
                        }}
                        renderOption={(props, option: UserListI) => (
                          <ListItem {...props} key={String(option.id)}>
                            <ListItemText
                              primary={option.nome}
                              secondary={option.email}
                              classes={{
                                primary: classes.primary,
                                secondary: classes.secundary,
                              }}
                            />
                          </ListItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            InputLabelProps={{
                              classes: {
                                root: classes.root,
                              },
                            }}
                            key={String(params.id)}
                            variant="outlined"
                            label="Selecione..."
                            placeholder="Selecione..."
                            className={classes.textInput}
                            helperText={errors.usuario_id?.message || messageError?.usuario_id}
                            FormHelperTextProps={{
                              className: classes.helperText,
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                    id="button-add-equipe"
                  >
                    {strings.addUser}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} className={classes.resumeTable}>
            <DataGrid
              rows={User}
              columns={columns}
              rowsPerPageOptions={[25, 50, 100]}
              autoPageSize
              autoHeight
              filterModel={{ items }}
              onFilterModelChange={(model) => setFilter(model)}
              localeText={GRID_DEFAULT_LOCALE_TEXT}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid container item xs={12}>
        <EditUser ref={editUserRef} />
      </Grid>
    </Grid>
  )
}

export default UserConsultancy
